import React from "react"
import { socialLinks } from "@util/whelpsData"

const Footer = () => {
    return (
        <div className="bg-blue-dark text-white border-t-4 border-blue-100 pt-8 pb-4 relative font-poppins-semibold">
            <img src="/logo.png" alt="logo image" className="absolute left-1/2 transform -translate-x-1/2 -top-16 w-28" />
            <div className="container w-full mx-auto">
                <div className="grid grid-cols-12">
                    <div className="col-span-12 lg:col-span-3 flex lg:block justify-around">
                        <div>
                            <h3 className="font-poppins-bold border-b-4 border-blue-100 uppercase text-lg sm:text-xl">
                                Collections
                            </h3>
                            <ul className="mt-1 uppercase">
                                <li>
                                    <a href="https://opensea.io/collection/whelpsnft" className="" target="_blank">Genesis</a>
                                </li>
                                <li>
                                    <a href="https://opensea.io/collection/whelpsnft-highborn" target="_blank">Highborn</a>
                                </li>
                                <li>
                                    <a href="https://opensea.io/collection/whelpsnft-dragonpass" target="_blank">DragonPass</a>
                                </li>
                                <li>
                                    <a href="https://opensea.io/collection/whelpsnft-pfp" target="_blank">Profile Pics</a>
                                </li>
                                <li>
                                    <a href="https://opensea.io/collection/whelpsnft-legacy" target="_blank">Legacy (ETH)</a>
                                </li>
                            </ul>
                        </div>
                        <div className="block lg:hidden">
                            <h3 className="font-poppins-bold border-b-4 border-blue-100 uppercase text-lg sm:text-xl">
                                Pages
                            </h3>
                            <ul className="mt-1 uppercase">
                                <li>
                                    <a href="/dragonpass" className="">DragonPass</a>
                                </li>
                                <li>
                                    <a href="/stake">Old Woman</a>
                                </li>
                                <li>
                                    <a href="/lore">Story & Lore</a>
                                </li>
                                <li>
                                    <a href="/artists">Artists</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-6 mt-6">
                        <div className="flex flex-col items-center">
                            <div className="flex justify-center">
                                {socialLinks.map((item, index) => (
                                    <a href={item.link} target="_blank" rel="noreferrer" className="mr-3" key={index}>
                                        <img src={item.icon} alt="social icon" className="w-9 sm:w-12" />
                                    </a>
                                ))}
                            </div>
                            <div className="flex items-center justify-center pb-5 text-white mt-4 lg:mt-10">
                                <div className="text-lg leading-8 text-white font-bold uppercase whitespace-nowrap">
                                    Powered by
                                </div>
                                <a href="https://masterbrews.com/" target="_blank" rel="noreferrer">
                                    <img src="/mb-logo.png" alt="logo icon" className="ml-3 md:max-w-xs" width="250" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-3 hidden lg:block">
                        <h3 className="font-poppins-bold border-b-4 border-blue-100 uppercase text-lg sm:text-xl">
                            Pages
                        </h3>
                        <ul className="mt-1 uppercase">
                            <li>
                                <a href="/dragonpass" className="">DragonPass</a>
                            </li>
                            <li>
                                <a href="/stake">Old Woman</a>
                            </li>
                            <li>
                                <a href="/lore">Story & Lore</a>
                            </li>
                            <li>
                                <a href="/artists">Artists</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
