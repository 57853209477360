import constants from "../config/constant";

const chainId = process.env.GATSBY_APP_CHAIN_ID;

export const NETWORK_ID         = chainId;
export const RPC_URL            = constants.rpc_url[chainId];
export const FORTMATIC_KEY      = constants.formatic_key[chainId];
export const INFURA_KEY         = constants.infura_key[chainId];

export const APP_NAME           = "WhelpS";
export const PORTIS_KEY         = "42bc626f-e174-4314-939e-bdb358d33930";
export const APP_URL            = "https://whelps.io";
export const CONTACT_EMAIL      = "keith@masterbrews.cards";
export const QUANTITY_POLL_TIME = 30000;
