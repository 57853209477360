export const INVALID_ID = -1

export const socialLinks = [
  {
    name: "twitter",
    link: "https://twitter.com/WhelpsNFT",
    icon: "/icon/icon_footer_twitter.png",
  },
  {
    name: "discord",
    link: "https://discord.gg/masterbrews",
    icon: "/icon/icon_footer_discord.png",
  },
  {
    name: "opensea",
    link: "https://opensea.io/collection/whelpsnft",
    icon: "/icon/icon_footer_opensea.png",
  },
  {
    name: "polyscan",
    link: "https://polygonscan.com/address/0x1f5567d9d3efa79c4b03beb42491344111efbdf5#code",
    icon: "/icon/icon_footer_polyscan.png",
  }
]
// data for team section on homepage
export const teamWhelpsData = [
  {
    name: "Highborn ",
    image: "/home/highborn.png",
    status: "/home/highborn-stats.png",
    width: "w-full",
    top: "-top-1/3",
    translateY: "translate-y-0"
  },
  {
    name: "Inferno ",
    image: "/home/inferno.png",
    status: "/home/inferno-stats.png",
    width: "w-1/2",
    top: "-top-1/4",
    translateY: "translate-y-0"
  },
  {
    name: "Ironlung ",
    image: "/home/ironlung.png",
    status: "/home/ironlung-stats.png",
    width: "w-3/4",
    top: "-top-1/4",
    translateY: "translate-y-0"
  },
  {
    name: "Tidal ",
    image: "/home/tidal.png",
    status: "/home/tidal-stats.png",
    width: "w-full",
    top: "-top-1/4",
    translateY: "translate-y-0"
  },
  {
    name: "Trakkor ",
    image: "/home/trakkor.png",
    status: "/home/trakkor-stats.png",
    width: "w-full",
    top: "-top-1/3",
    translateY: "translate-y-0"
  },
  {
    name: "Zephyr ",
    image: "/home/zephyr.png",
    status: "/home/zephyr-stats.png",
    width: "w-4/5",
    top: "-top-1/2",
    translateY: "translate-y-4"
  },
  {
    name: "Gravestone ",
    image: "/home/gravestone.png",
    status: "/home/gravestone-stats.png",
    width: "w-3/4",
    top: "-top-1/3",
    translateY: "translate-y-0"
  },
]

export const whelpsAbilityData = [
  {
    name: "Speed",
    color: "#ffd600"
  },
  {
    name: "Acceleration",
    color: "#ab47bc"
  },
  {
    name: "Maneuverability (Rotate)",
    color: "#ff6e40"
  },
  {
    name: "Weight (Braking)",
    color: "#03a9f4"
  },
]

export const teamData = [
  {
    name: "Adam Hudani ",
    image: "/home/team1.png",
    content: "Adam has held numerous senior level positions such as Chief Operating Officer, VP of Ops and Head of Operations for companies across numerous verticals such as i-gaming, ecommerce and social gaming. Adam has helped numerous companies grow from start-up status to industry leaders generating over $300M in revenue.",
    twitter: "https://twitter.com/TiltingS",
    linkedin: "https://www.linkedin.com/in/adam-hudani",
  },
  {
    name: "Keith Bussey",
    image: "/home/team2.png",
    content: "An experienced techpreneur with over 20 years in the online industry, Keith’s strengths lie in his resourcefulness, creativity and work ethic. As an aggressive CTO who delivers results, he has helped build, scale and guide companies in ecommerce (over $1B in sales), dating and mobile gaming - just to name a few.",
    twitter: "https://twitter.com/MasterBrewsNFT",
    linkedin: "https://www.linkedin.com/in/varius",
  },
  {
    name: "Alin Popa",
    image: "/home/team3.png",
    content: "Alin is a senior full-stack software engineer, teaching assistant, and graduate researcher. Passionate about computer science and innovation,Alin has been consistently delivering great software solutions.Alin is currently pursuing a Ph.D. in Financial Machine Learning, and has multiple IEEE-indexed research articles on cryptocurrency and neural networks.",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/alinn-popa",
  },
  {
    name: "Jason Silvert",
    image: "/home/team5.png",
    content: "Better known as ThePenguinVA, Jason specializes in customer relations management and acts as acommunity manager and occasional client liaison for MasterBrews. Prior to MasterBrews, Jason spent a decade as tech support with Apple. Previously Jason spent 10 years as a manager with Blockbuster Video. Jason has over 20 years of customer satisfaction experience.",
    twitter: "https://twitter.com/ThePenguinVA",
    linkedin: "https://www.linkedin.com/in/jason-silvert-6b623679",
  },
  {
    name: "DR. Marios Bounakis",
    image: "/home/team4.png",
    content: "Dr.BouMa is a Physicist with extensive studies in Particle Physics and Theoretical Cosmology, in which he has obtained a MSc and PhD, respectively. His hobbies, which include video and board gaming and early alpha testing, have made his consultation valuable to related projects in the cryptocurrency space.",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/marios-bounakis-295ab7109/",
  },
  {
    name: "Medina",
    image: "/home/team6.png",
    content: "Medina AKA jpmm21 is a young Cuban doctor who after fighting Covid-19 during 2021, was reborn as Web3 enthusiast. Medina now acts as Community Manager for MasterBrews.",
    twitter: "https://twitter.com/jpmm_21",
    linkedin: "https://www.linkedin.com/in/juan-pablo-medina-mulet-90114b11a",
  },
]
export const gameModesData = [
  {
    title: "Casual",
    icon: "/home/game_icon1.png",
    content: `
      <p>Essentially our Free2Play mode, players can race an unlimited number of   
      times wheather they hold an NFT from a qualifying WhelpS/Partner collection or not. </p>
      <p class="mt-2">Holders will be able to test their specific dragon/character’s different abilities and also earn a capped daily amount of Dragon tokens through time spend racing, whether by placing or unlocking badges and achievements. </p>`
  },
  {
    title: "Competitive",
    icon: "/home/game_icon2.png",
    content: `
      <p>This where the hardcore gamers live! NFT holders will be able to enter 
      races that require an entry fee paid in Dragon tokens.</p> 
      <p class="mt-2">Racers who place (finish in the top 3) will receive a weighted portion of the prize pool which is made up of the sum of all entry fees minus a small house fee which will be burned from the virtual currency supply. </p>
      <p class="mt-2">There is no daily cap in the amount of races a user can participate in and additional dragon tokens can be purchased from our no fee marketplace. </p>
      <p class="mt-2">There will be multiple competitive tiers and 
      various entry price points to ensure no one is priced out from participating in competitive mode. </p>
    `
  }
]

export const collectionData = [
  {
    title: "Council of Dragons",
    image: "/home/icon_council.png",
    content: "Bound to a life of secrecy the Council of Dragons are fearless leaders from the four corners of these lands, these individuals are men and women that operate only for the greater good. All of the Council of Daragon members somehow help toward the further progression of these lands, whether they are the leaders you see walking in the streets or the faceless engineers that provide water to your door. Tho the names and faces of these individuals may change like the seasons, just like the gems in the mines the ideas and morals behind this group are forever and one.",
    direction: "right"
  },
  {
    title: "Den of Dragons",
    image: "/home/icon_den.png",
    content: "The Den of Dragons is the local Brewhouse, located just down the road from the ancient ruins. The Den is a place where we come together to share some stories, play some games, and discuss the lands on which we reside. Not all the town may share their stories in here, but let there be no mystery, if the story is worthy it will leave the lips of someone in here.",
    direction: "left"
  },
  {
    title: "Community Wallet",
    image: "/home/icon_wallet.png",
    content: `After the Whelps mint was successfully completed a WhelpS Community Wallet was set up to hold the funds so that community members could see where the community allocated funds were going. The Community Wallets was originally funded with 44 ETH after the mint with most of the funds being allocated for the future development of a AAA P2E game.`,
    link: "https://etherscan.io/address/0xd8C2234eb681d9E4151E0b6A7fE7F365828b24aD",
    direction: "right",
    last: true
  },
]

export const funs = [
  { path: "/home/fun1.png", transform: "row-span-full col-start-2 col-span-3" },
  { path: "/home/fun2.png", transform: "row-span-full col-start-4 col-span-3" },
  { path: "/home/fun3.png", transform: "row-span-full col-start-6 col-span-3" },
  { path: "/home/fun4.png", transform: "row-span-full col-start-8 col-span-3" },
  { path: "/home/fun5.png", transform: "row-span-full col-start-10 col-span-3" },
  { path: "/home/fun6.png", transform: "row-span-full col-start-11 col-span-3" },
  { path: "/home/fun7.png", transform: "row-span-full col-start-13 col-span-3" },
  { path: "/home/fun8.png", transform: "row-span-full col-start-14 col-span-3" },
]

export const dragonBreedData = [
  {
    title: "Features",
    description: `They grow up so quickly, don't they? Starting from an Egg at Mint, 
    which along with the background image give subtle clues 
    to the breed you have adopted`,
    moreInfo: "/",
    image: "/news.png",
  },
  {
    title: "Section 3",
    description: `They grow up so quickly, don't they? Starting from an Egg at Mint, 
    which along with the background image give subtle clues 
    to the breed you have adopted`,
    moreInfo: "/",
    image: "/news.png",
  },
  {
    title: "Section 4",
    description: `They grow up so quickly, don't they? Starting from an Egg at Mint, 
    which along with the background image give subtle clues 
    to the breed you have adopted`,
    moreInfo: "/",
    image: "/news.png",
  },
]



// data for faq section on homepage
export const faqData = [
  {
    faqTitle: "Are the dragons all hand drawn?",
    faqDescription:
      "Yes! We do appreciate the work and aesthetics that goes into digital and 3D art and we plan on having special drops in those styles, we wanted to go a bit 'old school' and have each piece from the egg to the final evolved Adult to be all hand drawn!",
  },
  {
    faqTitle: "How long does it take for my WhelpS to evolve?",
    faqDescription:
      "After minting your WhelpS, you will receive a cute little egg set against a background which will give you hints as to the breed you've acquired. Every 30 hours, your WhelpS will EVOLVE from Egg, to Baby, to Whelp, to Young Adult and finally to a full Adult dragon! Each stage of evolution drops a new trait; some are rarer than others !",
  },
  {
    faqTitle: "How does the evolution work?",
    faqDescription:
      "Automated and 100% trustless! Our devs have come up with a genius method by which evolution requires zero user interaction, no scripts or decentralized code of any kind and no extra gas fees. This is breakthrough technology and if you'd like to find out more, please Join Us on Discord.",
  },
  {
    faqTitle: "How many different combinations of WhelpS are there?",
    faqDescription: "Over 138,000 unique combinations!",
  },
  {
    faqTitle: "How many Whelps exist?",
    faqDescription: "7,777",
  },
  {
    faqTitle: "How many breeds of dragon are there?",
    faqDescription:
      "Why, 7 of course! Actually, there are only 6 available through minting. The 7th is a shy fellow who will only come out to play once certain conditions are met.",
  }
]


export const roadmapData = [
  {
    status: "completed",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 4,
    rowSpan: 4,
    colStart: 2,
    colSpan: 5,
  },
  {
    status: "completed",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 9,
    rowSpan: 4,
    colStart: 2,
    colSpan: 5,
  },
  {
    status: "in-complete",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 1,
    rowSpan: 4,
    colStart: 4,
    colSpan: 5,
  },
  {
    status: "completed",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 7,
    rowSpan: 4,
    colStart: 4,
    colSpan: 5,
  },
  {
    status: "completed",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 12,
    rowSpan: 4,
    colStart: 4,
    colSpan: 5,
  },
  {
    status: "in-complete",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 4,
    rowSpan: 4,
    colStart: 6,
    colSpan: 5,
  },
  {
    status: "in-complete",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 9,
    rowSpan: 4,
    colStart: 6,
    colSpan: 5,
  },
  {
    status: "in-complete",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 2,
    rowSpan: 4,
    colStart: 8,
    colSpan: 5,
  },
  {
    status: "in-complete",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 7,
    rowSpan: 4,
    colStart: 8,
    colSpan: 5,
  },
  {
    status: "in-complete",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 12,
    rowSpan: 4,
    colStart: 8,
    colSpan: 5,
  },
  {
    status: "not-started",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 1,
    rowSpan: 4,
    colStart: 10,
    colSpan: 5,
  },
  {
    status: "not-started",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 9,
    rowSpan: 4,
    colStart: 10,
    colSpan: 5,
  },
  {
    status: "not-started",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 2,
    rowSpan: 4,
    colStart: 12,
    colSpan: 5,
  },
  {
    status: "not-started",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 6,
    rowSpan: 4,
    colStart: 12,
    colSpan: 5,
  },
  {
    status: "not-started",
    title: "Partenership",
    description: `Mark McKenna's "Heroes & Villains: Origins"`,
    rowStart: 11,
    rowSpan: 4,
    colStart: 12,
    colSpan: 5,
  },
]

export const walletData = [
  {
    walletImg: [
      { traitsImg: "/slide-14.png", traitsType: 'Baby' },
    ],
    currentEvolution: "Baby",
    nextEvolution: "Whelp",
    traitsLabel: ["horns", "breath", "iron"],
    traitsDetails: [
      {
        breed: "IRON",
        background: "GAVE",
      },
    ],
  },
  {
    walletImg: [
      { traitsImg: "/slide-11.png", traitsType: 'Baby' },
      { traitsImg: "/slide-14.png", traitsType: 'Baby' },
      { traitsImg: "/slide-14.png", traitsType: 'Whelp' },
      { traitsImg: "/slide-14.png", traitsType: 'Young Adult' },
      { traitsImg: "/slide-14.png", traitsType: 'Adult' },
    ],
    currentEvolution: "Adult",
    traitsLabel: ["horns", "breath", "iron", "trait", "trait"],
    traitsDetails: [
      {
        breed: "IRON",
        background: "GAVE",
        pattern: "NONE",
        expression: "CHILL",
        horns: "NONE",
        breath: "ACID",
      },
    ],
  },
  {
    walletImg: [
      { traitsImg: "/slide-11.png", traitsType: 'Baby' },
      { traitsImg: "/slide-14.png", traitsType: 'Baby' },
      { traitsImg: "/slide-14.png", traitsType: 'Whelp' },
      { traitsImg: "/slide-14.png", traitsType: 'Young Adult' },
      { traitsImg: "/slide-14.png", traitsType: 'Adult' },
    ],
    currentEvolution: "Adult",
    traitsLabel: ["horns", "breath", "iron", "trait", "trait"],
    traitsDetails: [
      {
        breed: "IRON",
        background: "GAVE",
        pattern: "NONE",
        expression: "CHILL",
        horns: "NONE",
        breath: "ACID",
      },
    ],
  },
  {
    walletImg: [
      { traitsImg: "/slide-02.png", traitsType: 'Baby' },
    ],
    currentEvolution: "Baby",
    nextEvolution: "Whelp",
    traitsLabel: ["horns", "breath", "iron"],
    traitsDetails: [
      {
        breed: "IRON",
      },
    ],
  },
  {
    walletImg: [
      { traitsImg: "/slide-02.png", traitsType: 'Baby' },
    ],
    currentEvolution: "Baby",
    nextEvolution: "Whelp",
    traitsLabel: ["horns", "breath", "iron"],
    traitsDetails: [
      {
        breed: "IRON",
        background: "GAVE",
      },
    ],
  },
]

export const loreData = [
  {
    loreTitle: "Inferno",
    loreImg: "/slideDragons/dragon-inferno.png",
    loreContent: [
      "The hearts of volcanoes are gateways to massive chasms full of magma in the planet’s crust—roiling seas of liquid rock. Inside these vast caverns, there are probably many Inferno dragons who happily live their entire lives without ever venturing out into the comparatively chilly outside world.",
      "Nevertheless, some impulsive, mischievous Inferno dragons leave their fiery home in the planet’s crust to dwell on the surface—perhaps after getting into arguments with their families and friends. Inferno WhelpS are notoriously adorable, with squat snouts and toasty little licks of breath when they’re excited, but they’re also mischievous, clever, and fond of practical jokes. Assume any Inferno WhelpS who have lived around humans know human language—spoken and written—and can manipulate any human tool with their agile claws and mouths.",
      "One of the most ancient archeological records of dragons concerns Inferno dragons: the Stele of the Dragons tells the tale of a wild Inferno who was lured to a village with sacrifices of goats and sheep. He taught a primitive form of writing to the people there in thanks, by scratching symbols into the dust with his claws and burning precise marks into the arid vegetation of a nearby range of mountains. The mountains still bear traces of these marks and are referred to as the School Hills even today.",
      "Full-grown, Infernos care deeply about their human or dragon families and communities, but they are invariably stubborn and of independent mind. Many a king has dreamed of making an Inferno into a powerful war-beast at the heart of his army, but they invariably learn their lesson when their Inferno has taken matters of tactics and strategy into their own hands. A wise ruler raises up an Inferno with an eye to command, for despite their impulsiveness, Infernos have an instinctual understanding of combat. A single Inferno dragon confers a considerable advantage on any army that is lucky enough to be led by one, but it is wise not to fight alongside a group of Infernos, as the chance of them fighting among themselves is too great.",
      "In peacetime, Infernos will defend their communities from wild beasts, and—with their instinctual understanding of fire—they are expert at stopping wildfires through their judicious application of counter-fires. They easily learn to read, write, and figure, and while only some Infernos have the patience to go to the effort of pronouncing human speech, all can learn to communicate in sign—generally by using a complex lexicon of visible symbols produced with their dragon breath.",
    ],
    loreBg: "/slideDragons/slide-inferno.png",
  },
  {
    loreTitle: "Trakkor",
    loreImg: "/slideDragons/dragon-trakkor.png",
    loreContent: [
      "In ancient times, the historic peoples whose annals bring us most of our knowledge about dragons did not know about Trakkor dragons until their trade routes and paths of conquest reached into the far east and south: places where warm, humid conditions and strong equatorial sunlight gave rise to massive, trackless jungles. In these regions, Trakkor dragons and people lived in such close harmony that their cultures, languages, and art-forms became deeply intertwined.",
      "With their hooked front limbs, comparatively small size, and deep understanding of the ecology of the jungles they live in, Trakkor dragons have helped their communities of humans and dragons survive in difficult conditions, locating rare and magical plants and creatures and spotting danger—or prey—from a great distance, hooked into the treetops or clinging to mountain peaks.",
      "In the deepest rainforests, Trakkor dragons used their climbing skills and their deep knowledge of the terrain to change the environment forever. There are multiple species of food-bearing vine-plants that flourish even today in equatorial rainforests, due to the efforts of ancient Trakkor dragons to plant them at the crowns of trees.",
      "The speedy, agile Trakkors were known to be taciturn, and while Trakkor WhelpS easily learned to read and understand human languages, their direct communication was largely by action, not deed—charting a secret route for a hasty retreat or finding a food source for a starving community. In combat, Trakkors made excellent messengers and, armed with magical flares, scouts—but would only defend themselves and their loved ones in an emergency.",
      "Far-flung hermits, dedicated gardeners, and explorers of the deepest green wilds are discovering Trakkor eggs—sometimes tucked inside their packs and bedrolls. The hidden redoubts and grand temples built by the vibrant cultures that once lived side by side with Trakkors are also finding the eggs, nestled in gables or among the offerings at sacred shrines."
    ],
    loreBg: "/slideDragons/slide-trakkor.png",
  },
  {
    loreTitle: "Tidal",
    loreImg: "/slideDragons/dragon-tidal.png",
    loreContent: [
      "Tidal dragons can swim like a fish, but they’re not fish—they breathe air, but are able to close their lungs for extended periods of time and to dive to incredible depths and pressures. Though, like all dragons, they have not been subject to confirmed sightings for eons, we know a great deal about Tidal dragons because the fisherpeople, merchants, traders and explorers of the world’s oceans have made sure that as much knowledge of these dragons is preserved as possible. Sailors still know how to make the delicate webs of rope known as dragon nests; while these often carry supplies or shelter sleeping sailors these days, dragon nests were literally invented as safe resting places for the Tidal dragons who cast their lots in with the lucky crews of merchanters, battle-ships or even pirates.",
      "Tidal dragons are some of the most cooperative in nature of any of the dragon breeds, and working as a team they can take down the gargantuan monsters of the deep: the krakens. The world’s mariners are very excited about the potential of a new Era of Dragons opening up the trading routes that have long been closed due to the horrible fate that is in store for a ship dragged into the deep by a kraken.",
      "The cooperative nature of Tidal dragons gives them the greatest utility of any dragon breed on the battlefield, as they can serve as cavalry, artillery, sappers or bombardiers, and are as comfortable taking orders as they are in command. Historians believe that the higher level of social and technological complexity of coastal societies in ancient times may have occurred because their coexistence with Tidal dragons gave them considerable competitive advantages.",
      "Tidal eggs can be found buried in soft beach sand, wrapped up in parcels of seaweed, or among jumbles of water-worn stones. They are often found in shallow rock-pools and will happily mature and hatch even while wet. Numerous eggs are being discovered daily in the homes of fishing families, the bilges of ships, and the docks of shipbuilders.",
    ],
    loreBg: "/slideDragons/slide-tidal.png",
  },
  {
    loreTitle: "Gravestone",
    loreImg: "/slideDragons/dragon-gravestone.png",
    loreContent: [
      "No dragon breed is more deeply entrenched in our cultural consciousness than Gravestone dragons. That’s because some of the most popular poems, songs and plays depict Gravestone dragons as symbols of love, loyalty, fidelity and grief. The old folk wisdom tells us that a young Gravestone will bond with a human who is consumed with the sadness of loss, and will be loyal to that person—and their family—for as long as that dragon lives, which can be a very long time indeed.",
      "Gravestone dragons have the characteristic look and feel of stone, and when they hold still they are indistinguishable from the guardian dragon sculptures that grace our most illustrious buildings. The legends tell us that the most ancient of these sculptures are actually living dragons that have become dormant, but will come to life if the families they protect are in dire need.",
      "From our present understanding, gleaned from historic accounts, myths, and folktales, Gravestone dragons are nearly impervious to extremes of heat or cold, and hold stock-still when resting, but can move with great speed when roused to action. Of all the dragon breeds, they are among the most fluent in human speech, at least with those to which they are loyal, but their loyalties are so all-encompassing that it is difficult to deploy Gravestones in combat. By the same token, Gravestones make excellent bodyguards.",
      "Although some adventurers have discovered what seem to be Gravestone eggs in the deepest catacombs and scattered on fields of battle, most of these eggs are turning up in the ordinary domains of people who have suffered a tragic event. One young woman found the egg tucked into the never-used cradle of her much wanted first child, who sadly died before birth. One can hope that these WhelpS mature into dragons that can bring some good fortune into the lives of many.",
    ],
    loreBg: "/slideDragons/slide-gravestone.png",
  },
  {
    loreTitle: "Zephyr",
    loreImg: "/slideDragons/dragon-zephyr.png",
    loreContent: [
      "These dragons, comparatively smaller than most of the rest of their cousins, were often depicted in ancient art as the companions of the gods and heroes: ushering in the sunrise, fleeing in advance of an earthquake, or bringing messages to an angry warrior or a love-sick princess. Present in many societies—advanced civilisations and isolated cultures alike—Zephyrs have nearly no aggressive characteristics in their temperament, and are safe to leave with defenceless children and animals. Zephyrs have served as shepherds—dashing nearly instantaneously to the townspeople to warn of predators—and as scouts, messengers and even spies in battle.",
      "The most memorable characteristic of Zephyrs is their speed; they are the fastest creatures on the planet, whether by land, sea or air. They can fly so fast that the ancients once thought that they could teleport, and ancient sages and geometers once believed that a Zephyr in full flight was the fastest that anything in the universe could ever go, and that their passing broke the air and unleashed spirits of thunder. Natural philosophers, magicians and alchemists have since determined that Zephyrs were actually breaking the sound barrier!",
      "Beautiful to behold, Zephyrs look like snakes—their clawed feet are nearly vestigial in nature—and hunt tiny vermin through stealth and speed. They are nearly silent, rarely roar or emit dragons’ breath, and communicate with each other and with people through what seems to be a form of telepathy. This makes their presence and counsel especially prized in the households of nobles, rulers and merchants. The loveliness and unusual powers of Zephyrs has led them to be leaders of dragon and human communities, but has also put them at risk of persecution when societies have turned against dragonkind.",
      "Zephyr eggs are being discovered in very strange places—windswept moors and gale-battered heights, but also deep inside mighty fortresses, in the straw of travelling caravans, in the inviolate safes of counting-houses and family vaults, and on merchant ships, where they keep close company with Tidal dragons.",
    ],
    loreBg: "/slideDragons/slide-zephyr.png",
  },
  {
    loreTitle: "Ironlung",
    loreImg: "/slideDragons/dragon-ironlung.png",
    loreContent: [
      "Very little is known about this elusive dragon type, and descriptions of Ironlungs’ appearance, behaviour and characteristics vary widely in historical and archaeological records. What is broadly understood is that “wild” Ironlungs—WhelpS raised entirely by dragonkind—are unheard of, raising speculation that this type of dragon evolved, or was created, by human beings and dragons with deep arcane and scientific knowledge.",
      "Ancient records depict Ironlungs in secret conclaves with alchemists, enchanters and natural philosophers, and a famous etching that hangs in the bedroom of Duke S———‘s favourite mistress shows the anatomy of an Ironlung’s eye. The drawing, dated to about 700 years ago, shows an eye with clockwork interwoven with the muscles that shape the eye’s lens and control its focus. Observers speculate that the mature Ironlung can control its field of vision, giving it the capacity to see things that are very far away or very small. We have no diagrams of the other sensory apparatus of the Ironlung, but it is possible that their auditory and olfactory senses are as powerful—and as finely controllable—as their eyesight.",
      "The name “Ironlung” comes from an ancient account of a legendary poetic recital, in which humans and dragons competed to win a golden cup. The winning poet was a dragon mage who recited a three-hour ballad without taking a single breath. This could mean that the dragon has robust control of breathing—or that it has the capacity to broadcast its speech telepathically.",
      "In the current Era of Dragons, people have been finding what look to be Ironlung eggs in strange places: on the most forgotten, dusty shelves of laboratories, in the forges of talented blacksmiths, and resting on the blasted, scarred workbenches of alchemists and tinkerers. A famous travelling circus, known for its skilful use of theatrical tricks, found what looks like an Ironlung egg in a stage magician’s box, and they are selling tickets to the hatching.",
    ],
    loreBg: "/slideDragons/slide-ironlung.png",
  },
]

export const artistsData = [
  {
    artImg: '/artists/Mckenna.png',
    artName: 'Mark Mckenna',
    artProfile: 'MarkMcKenna419'
  },
  {
    artImg: '/artists/skinner.png',
    artName: 'Chris Skinner',
    artProfile: 'SkinnerCreative'
  },
  {
    artImg: '/artists/song.png',
    artName: 'Allen Song',
    artProfile: 'asong0116'
  },
  {
    artImg: '/artists/chan.png',
    artName: 'Larienne Chan',
    artProfile: 'lariennearts'
  },
  {
    artImg: '/artists/duarte.png',
    artName: 'Rui Duarte',
    artProfile: 'RRddraft'
  },
  {
    artImg: '/artists/white.png',
    artName: 'James White',
    artProfile: 'Signalnoise'
  },
]

export const derivateArtistsData = [
  {
    deArtistsImg: '/artists/calaveritas.png',
    deArtistsName: 'Bored Calaveritas',
    deArtistsProfile: 'calaveritas_eth'
  },
  {
    deArtistsImg: '/artists/board-apes.png',
    deArtistsName: 'Board Apes',
    deArtistsProfile: 'BoardApes'
  },
  {
    deArtistsImg: '/artists/drip-bits.png',
    deArtistsName: 'dripbits',
    deArtistsProfile: 'dripbitsnft'
  },
  {
    deArtistsImg: '/artists/street-ape-yc.png',
    deArtistsName: 'Street BAYC',
    deArtistsProfile: 'StreetApeYC'
  },
  {
    deArtistsImg: '/artists/space-bored-ape.png',
    deArtistsName: 'Space BAYC',
    deArtistsProfile: 'SpaceBoredApe'
  },
]

export const bgNames = {
  "ironlung": {
    "bg0": "Cavern - Grey",
    "bg1": "Cavern - Teal Wonder",
    "bg2": "Crystal Cave - Chocolate",
    "bg3": "Stalagmites - Silver",
    "bg4": "Prison - Bronze",
    "bg5": "Cavern - Purple Delight",
    "bg6": "Crystal Cave - Emerald",
    "bg7": "Mine Shaft",
    "bg8": "Stalagmites - Purple Haze",
    "bg9": "Prison - Iron"
  },
  "trakkor": {
    "bg0": "Magic Meadows - Green",
    "bg1": "Ancient Glades - Green",
    "bg2": "Ancient Glades - Bronze",
    "bg3": "Arboreal Den",
    "bg4": "Arboreal Prison",
    "bg5": "Magic Meadows - Gold",
    "bg6": "Secret Tunnel",
    "bg7": "Dirt Summit",
    "bg8": "Lush Caverns",
    "bg9": "Hypnotic Pathway"
  },
  "tidal": {
    "bg0": "Chill Waters - Bronze",
    "bg1": "Calm Islet - Green",
    "bg2": "Calm Islet - Bronze",
    "bg3": "Glacial Waters - Near",
    "bg4": "Glacial Waters - Far",
    "bg5": "Chill Waters - Pink",
    "bg6": "Tidal Rift - Beach",
    "bg7": "Playtime Pool",
    "bg8": "Azure Lagoon",
    "bg9": "Tidal Rift - Highway"
  },
  "zephyr": {
    "bg0": "Summit - Hurricane",
    "bg1": "Nest - Green",
    "bg2": "Summit - Zen",
    "bg3": "Stalagmites - Freedom",
    "bg4": "Wind Tunnel",
    "bg5": "Summit - Bizarro",
    "bg6": "Open Skies",
    "bg7": "Nest - Golden",
    "bg8": "Hypnotic Airway",
    "bg9": "Stalagmites - Mystery"
  },
  "inferno": {
    "bg0": "Lake - Lava",
    "bg1": "Warm Cave",
    "bg2": "Desert Life",
    "bg3": "Scorched Earth",
    "bg4": "Stalagmites - Ember",
    "bg5": "Red Rocks",
    "bg6": "Mountain Tunnel",
    "bg7": "Valley - Sunset",
    "bg8": "Valley - Death",
    "bg9": "Lake - Volcanic"
  },
  "gravestone": {
    "bg0": "Glory Plains - Green",
    "bg1": "Valley - Green",
    "bg2": "Hypnotic Passage - Green",
    "bg3": "Mammoth Crossing",
    "bg4": "Basilisk Ridge",
    "bg5": "Flooded Plains",
    "bg6": "Valley - Bronze",
    "bg7": "Hypnotic Passage - Gold",
    "bg8": "Valley - Eternal",
    "bg9": "Glory Plains - Gold"
  }
}

// data for faq section on homepage
export const faqDataOnStake = [
  {
    faqTitle: `How Can I Obtain a Highborn?`,
    faqDescription:
      `All Whelps carry with them a bit of "spirit essence" from the original breed; long thought to be extinct. There is an old woman, who legends say can guide this energy into an appropriate vessel and return them to life. By visiting this woman, you will be able to transfer the spirit from your Whelps, into an empty, ancient egg.`,
  },
  {
    faqTitle: `What Does Staking Mean?`,
    faqDescription:
      `Staking, can be thought of like making a deposit to a bank account, where your money is then frozen for a set period of time. You may not list, sell, transfer burn or use your Whelps for any other purpose while they are staked and unavailable to you. 

      The staking time, depends on how many different breeds of Whelps you choose to stake at the same time (from one through six). The Highborn breed may never be staked for this purpose, as its energy has already matured and would prove useless.

      STAKING TABLE: 

      1 Whelp = 35 days to acquire egg. 
      2 Whelps = 28 days to acquire egg. 
      3 Whelps = 21 days to acquire egg. 
      4 Whelps = 14 days to acquire egg. 
      5 Whelps = 7 days to acquire egg. 
      6 Whelps = 3 days to acquire egg.      
      `,
  },
  {
    faqTitle: `Do I Get My WhelpS Back?`,
    faqDescription:
      `Yes, but not immediately. Having this spirit essence drawn from their bodies is quite an ordeal and all Whelps used in such a manner, must rest for 45 days after you acquire the egg before they can return, happy and healthy, to their owner.`,
  },
  {
    faqTitle: `How Long Does Evolution Take?`,
    faqDescription:
      `Evolution of these special eggs will take place as with the original Whelps. However, there will be 5 days between stages, as opposed to 30 hours.`,
  },
  {
    faqTitle: `Does the Rarity of My Staked WhelpS Matter?`,
    faqDescription:
      `No. However, the larger variety of breeds you stake will have an influence on the outcome, in that it will increase your probability rate to hit a rare trait or higher number of traits.`,
  },
  {
    faqTitle: `Can I Stake My WhelpS More Than Once?`,
    faqDescription:
      `No, the spirit essence is something they are born with and doesn't replenish. However, you will still be able to use your Whelps in any other form of utility or activity the future brings.`,
  },
  {
    faqTitle: `Enough Talk! How Much Will This Cost Me?`,
    faqDescription:
      `The old woman is a generous type...staking is FREE! Unfortunately, as with any transactions on the Ethereum plane, the Dwarven miners take their usual cut (you must pay gas).`,
  },
]

export const dragonVirtualData = [
  {
    name: "inferno",
    image: "/dragons/dragon-01.png",
    address: "NFT #1",
    selected: false,
    staked: false,
  },
  {
    name: "gravestone",
    image: "/dragons/dragon-02.png",
    address: "NFT #1",
    selected: false,
    staked: false,
  },
  {
    name: "inferno",
    image: "/dragons/dragon-01.png",
    address: "NFT #1",
    selected: false,
    staked: false,
  },
  {
    name: "Trakkor",
    image: "/dragons/dragon-04.png",
    address: "NFT #1",
    selected: false,
    staked: false,
  },
  {
    name: "inferno",
    image: "/dragons/dragon-01.png",
    address: "NFT #1",
    selected: false,
    staked: false,
  },
  {
    name: "ironglung",
    image: "/dragons/dragon-03.png",
    address: "NFT #1",
    selected: false,
    staked: false,
  },
  {
    name: "Trakkor",
    image: "/dragons/dragon-04.png",
    address: "NFT #1",
    selected: false,
    staked: false,
  },
  {
    name: "Zephyr",
    image: "/dragons/dragon-05.png",
    address: "NFT #1",
    selected: false,
    staked: false,
  },
  {
    name: "Tidal",
    image: "/dragons/dragon-06.png",
    address: "NFT #1",
    selected: false,
    staked: false,
  },
]

export const partnerPerksData = [
  {
    title: 'Mark McKenna’s Heroes & Villains: Origins',
    content: `Heroes & Villains: Origins" will take you on an NFT journey unlike any other, as you witness first-hand the events that shape a Hero or a Villain through their story (evolution).`,
    perks: [
      "48-hour presale access allowing you to purchase up to 2 mints per wallet in advance.",
      "Earn 25 Dragon Tokens per character you mint.",
      `Whelps' related "easter eggs" included in the collection.`
    ],
    backtype: 1,
    twitter: "https://twitter.com/HV_Origins",
    discord: "https://discord.com/invite/masterbrews",
    website: "https://heroesandvillains.io/"
  },
  {
    title: 'Crypto Whale Club',
    content: 'A collection of unique NFT Atlantean whales, each representing one of 20 crypto coins or tokens, living on the Ethereum blockchain. They are forged from 100+ different attributes/traits with a maximum of 8,888 whales minted.',
    perks: [
      "48-hour presale access",
      `WhelpS “Easter Eggs”`,
      `25 Dragon Tokens per CWC you mint!`
    ],
    backtype: 2,
    twitter: "https://twitter.com/cryptowhaleclub",
    discord: "https://discord.gg/cryptowhaleclub",
    website: "https://cryptowhaleclub.io/"
  },
  {
    title: 'White Rabbit',
    content: 'Everything you know about reality, information and limitations are about to be turned upside down. The White Rabbit is here to be your friend and your key, your guide for your curiosity as we dive deep into the Rabbit Hole of Metaverse. As a holder of the White Rabbit NFT, it will open doors to interconnected utilities that welcome you to build and shape this new dimension with the GATE community. Follow the White Rabbit, now let’s cause some trouble.',
    perks: [
      "24 hour presale access",
      `Exclusive discord role`,
      `Post mint raffle opportunity`
    ],
    backtype: 2,
    twitter: "https://rabbitff.com/#",
    discord: "https://bit.ly/31iC9oK",
    website: "https://rabbitff.com/"
  },
  {
    title: 'COMING SOON',
    content: '',
    perks: [],
    backtype: 1,
    twitter: "",
    discord: "",
    website: ""
  },
]


export const slipStreamData = [
  {
    image: "/slipstream/bg_1.png",
    title: "Game FAQ",
    content: [
      {
        title: "What genre will the game be?",
        content: "racing, in the style of mario kart / crash bandicoot racing."
      },
      {
        title: "Will I be required to own a Dragonpass?",
        content: "No. However, certain game modes, tournaments or promotions (such as Early Access) will only be available to Dragonpass holders. You will also need one to have your achievements and gameplay statistics tracked on-chain."
      },
      {
        title: "Does this mean there will be power-ups and battle aspects?",
        content: "That is exactly what it means."
      },
      {
        title: "Will Spirit Essence play a role within the game?",
        content: "yes, we are finalizing the exact utility details. look for more details to come."
      },
      {
        title: "Will they drive Go-Karts or other vehicles?",
        content: "no, whelps will fly (even trakkor!) allowing for additional moves and gameplay features."
      },
      {
        title: "will twins, triplets and quadruplets play a role within the game?",
        content: "Yes, we have something special planned for them. Look for more details to come."
      },
      {
        title: "Will I require a WhelpsNFT in order to play?",
        content: "Yes. You must own at least one Genesis (Polygon version) or Highborn WhelpsNFT in order to play the game. In the future, this will be expanded upon to allow for growth."
      },
      {
        title: "Do breed/traits matter for gameplay, or are they purely cosmetic?",
        content: "Breed matters. Some traits matter, for example breath type. Others, like body pattern, are purely cosmetic."
      },
      {
        title: "Do staked Whelps count?",
        content: "No, as the Whelp must be in your wallet. If they are currently staked elsewhere, doing another task, they would not be available to run races or breed until they are available and in your wallet once more."
      },
      {
        title: "Can I race with a Breed or Traits I don't own?",
        content: "No, the Whelps available to you in the game will match those available in your wallet."
      },
    ]
  },
  {
    image: "/slipstream/bg_2.png",
    title: "Game development FAQ",
    content: [
      {
        title: "When will the playable demo be released?",
        content: "we expect the release in late may, 2022."
      },
      {
        title: "Will the funds in the community wallet cover the cost of the game?",
        content: "No. However, certain game modes, tournaments or promotions (such as Early Access) will only be available to Dragonpass holders. You will also need one to have your achievements and gameplay statistics tracked on-chain."
      },
      {
        title: "Where will the game be playable?",
        content: "the initial version will be available on desktop for pc. in the future, as the game succeeds and grows, we hope to release it across other channels (ios app, android app, console)."
      },
      {
        title: "Who will handle the Blockchain-related aspects?",
        content: "MasterBrews will have a dedicated team for this purpose."
      },
      {
        title: "Why not browser-based?",
        content: "This will be a AAA-quality game and opting for a browser- based experience using WebGL has technical and visual limitations."
      },
      {
        title: "When can we see the first Concept Art?",
        content: "While the first full 3D model will take roughly a month to finish, we hope to begin showing Weekly Updates of the creation process starting December 20th or so."
      },
      {
        title: "Who are the Game Studio developing the game?",
        content: "https://www.argentics.io are our game development partners."
      },
    ]
  },
  {
    image: "/slipstream/bg_3.png",
    title: "P2E / Tokenomics FAQ",
    content: [
      {
        title: "Will there be a token?",
        content: "yes. we will have an in-game virtual currency (dragon tokens) as well as an erc20 governance token ($whelp)."
      },
      {
        title: "Will I be able to start earning before the game releases ?",
        content: "Yes, you will be able to get a jumpstart on earning our virtual currency through a variety of methods. Due to the rules set forth by the Launchpads, you won't be able to earn $WHELPs in advance of the IGO allocations."
      },
      {
        title: "In what ways can I earn?",
        content: "Breeding, In-Game NFT Rewards & In-Game currency. Future additional methods may be added, such as a Rental system."
      },
      {
        title: "When will the token release and how can I earn it?",
        content: "We are currently negotiating with various GameFi Launchpads and they all require further development milestones before committing to a scheduled launch and agreement."
      },
    ]
  },
  {
    image: "/slipstream/bg_4.png",
    title: "Dwarves and Mining FAQ",
    content: [
      {
        title: "Dwarves?",
        content: "dwarves! if you have been following our lore, dwarves play an important role in the history (and future) of whelps! we will be releasing a new collection, exploring their world a little closer. i wonder if the old woman or dwarven king might make an appearance?"
      },
      {
        title: "Will Whelps serve any purpose in Dwarven Mining?",
        content: "Yes! We, hmmmm, may have neglected to mention the dark, shadowy souls who also inhabit these mountains. Many a Dwarf wakes up from a night's worth of mead only to find some of their spoils missing. They often enlist the aid of eager Whelps to protect their goods and scare off those dastardly night gremlins."
      },
      {
        title: "what are the drop details?",
        content: "The price, supply and date are all being discussed and finalized and when we know, you'll know! We are aiming for sometime in January."
      },
      {
        title: "Is this part of the game?",
        content: "No, this will be considered a staking mini-game of sorts outside the actual game, allowing you to earn tokens and eventually, additional rewards. This will take place through the website and smart contracts."
      },
      {
        title: "Mining?",
        content: "Dwarves are passionate about very few things in life, but lucky for you, one of them happens to be mining the insides of mystical mountains for precious stones and materials. At first, they'll be able to smelt what they find into Dragon Tokens (in-game currency), but as they dig deeper, who knows what treasures they may unearth! Rumor has it, there are large pockets of $WHELP hiding somewhere in there."
      },
    ]
  },
  {
    image: "/slipstream/bg_5.png",
    title: "Miscellaneous FAQ",
    content: [
      {
        title: "What happened to Koin Games?",
        content: "our relationship is healthy as ever and everything is proceeding according to plan with bloodlines. we expect to have much more news to share on that in early 2022."
      },
      {
        title: "What are your marketing plans ?",
        content: "In January, we will begin marketing campaigns in the NFT and P2E spaces to share the news, first looks, trailers and such. Once the playable demo is out, we will go hard and heavy at pushing this EVERYWHERE and, once the Launchpad IGO comes around, that should bring MAJOR exposure. We can also leverage more traditional forms of marketing, given our game will be high-quality, fun and have mainstream appeal."
      },
      {
        title: "Will you involve other projects /communities?",
        content: "This early, nothing is set in stone, but we do imagine one day exploring a path where we can allow others to appear in our game as playable characters or feature them in our new race course designs, cosmetic upgrades and such."
      },
    ]
  },
]


export const dwarvesData = [
  {
    name: "DWARVEN SMELTER",
    info: "Some of the strongest Dwarves around! They specialize in pounding the Mystic Ore into fresh dragon tokens. They make a decent living by collecting a 10% fee for their services.",
    detail: "1 in 50",
    img: "/dwarves/dwarf_2.png",
    color: "yellow-100"
  },
  {
    name: "DWARVEN PROSPECTER",
    info: "Better than a pig sniffing out truffles! These dwarves are experts when it comes to selecting the best spots to mine! They will surly accelerate the rate at which your Miners accrue Mystic Ore.",
    detail: "1 in 20",
    img: "/dwarves/dwarf_1.png",
    color: "gray-light"
  },
  {
    name: "DWARVEN BARD",
    info: "These musically talented Dwarves are great at keeping morale high and preventing fatigue.",
    detail: "1 in 8",
    img: "/dwarves/dwarf_3.png",
    color: "blue-light"
  },
  {
    name: "DWARVEN MINER",
    info: "Your blue-collar workers, they mine Mystic Ore, or course!",
    detail: "1 in 1.25",
    img: "/dwarves/dwarf_4.png",
    color: "red-light"
  },

]
