export default {
  rpc_url: {
    1: 'https://mainnet.infura.io/v3/2a2d9707c8ce4348b24a042e591c3d5e', // Ethereum Mainnet
    4: 'https://rinkeby.infura.io/v3/fecb4f8dbd8e4be792f6945c23f8fd3d', // Ethereum Rinkeby
    137: 'https://polygon-mainnet.infura.io/v3/2a2d9707c8ce4348b24a042e591c3d5e' // Polygon Mainnet
  },
  infura_key: {
    1: '2a2d9707c8ce4348b24a042e591c3d5e', // Ethereum Mainnet
    4: 'fecb4f8dbd8e4be792f6945c23f8fd3d', // Ethereum Rinkeby
    137: '2a2d9707c8ce4348b24a042e591c3d5e' // Polygon Mainnet
  },
  formatic_key: {
    1: 'pk_live_9F32123A9D83F8DA',
    4: 'pk_test_DC79864314971EB1',
    137: 'pk_live_9F32123A9D83F8DA'
  }
}
