import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Disclosure } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { useMeasure } from "react-use"
import { ToastProvider } from 'react-toast-notifications';
import { connect } from "react-redux"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { ChainID, getWeb3, getEthWeb3, getPolWeb3, getOnBoard } from "@util/wallet"
import { setChainId, setWeb3, setEthWeb3, setPolWeb3, setOnBoard, setWalletAddress, setConnected } from "@state/actions"
import { socialLinks } from "@util/whelpsData"
import { Menus } from "./menu"
import Footer from "./Footer"
import "./Layout.scss"

const Layout = ({
  title,
  contentClassName,
  page,
  children,
  headerClass = "",
  headerStyle = null,
  visibleClass = true,
  web3,
  onBoard,
  walletAddress,
  connected,
  setChainId,
  setWeb3,
  setEthWeb3,
  setPolWeb3,
  setOnBoard,
  setConnected,
  setWalletAddress
}) => {
  const [ref, /*{ height, bottom }*/] = useMeasure()

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  })

  useEffect(() => {
    setWeb3(window.__web3 || null);
    setOnBoard(window.__onBoard || null);
    setWalletAddress(window.__walletAddress || null);
    setConnected(window.__connected || false);
  }, []);

  useEffect(() => {
    window.__web3 = web3;
  }, [web3]);
  useEffect(() => {
    window.__onBoard = onBoard;
  }, [onBoard]);
  useEffect(() => {
    window.__walletAddress = walletAddress;
  }, [walletAddress]);
  useEffect(() => {
    window.__connected = connected;
  }, [connected]);

  useEffect(() => {
    const addressAvailable = () => {
      if (walletAddress) {
        return;
      }

      if (web3 && web3.currentProvider && web3.currentProvider.selectedAddress &&
        (web3.currentProvider.selectedAddress.length > 0)) {
        setWalletAddress(web3.currentProvider.selectedAddress);
      } else {
        setTimeout(addressAvailable, 100);
      }
    }

    if (web3) {
      addressAvailable();
    }
  }, [web3, walletAddress]);

  useEffect(() => {
    const walletInitialize = async () => {
      const _web3 = await getWeb3()
      const _ethWeb3 = await getEthWeb3()
      const _polWeb3 = await getPolWeb3()
      const _onBoard = await getOnBoard()
      const _chainId = await _web3.eth.getChainId()
      const _address = await _web3.eth.getAccounts()

      setChainId(_chainId)
      setWeb3(_web3)
      setEthWeb3(_ethWeb3)
      setPolWeb3(_polWeb3)
      setOnBoard(_onBoard)
      if (_address[0] && _chainId === ChainID)
        setConnected(true)

      setWalletAddress(_address[0])
    }

    if (typeof window !== "undefined") {
      if (window.ethereum) {
        window.ethereum.on('chainChanged', handleNetworkChange);
        window.ethereum.on('disconnect', logout);
        window.ethereum.on('accountsChanged', logout);
      }
    }

    walletInitialize()
  }, [])

  const connectHandler = async () => {
    if (onBoard !== null) {
      if (!(await onBoard.walletSelect())) {
        return;
      }
      setConnected(await onBoard.walletCheck())
    }
  }

  const logout = async () => {
    const _web3 = await getWeb3()
    const _chainId = await _web3.eth.getChainId()
    setChainId(_chainId)
    if (onBoard != null) {
      onBoard.walletReset();
    }

    if (![1, 4, 137].includes(_chainId)) {
      setWalletAddress(null);
      setConnected(false)
    }
  }

  const handleNetworkChange = (networkId) => {
    logout();
    if (networkId !== '0x89') {
      displayNotify("warning", "You've just changed networks! The app will not function properly unless you are on Polygon Mainnet.")
    }
  }

  const sliceAddress = (val) => {
    return val !== null ? val.slice(0, 6) + '...' + val.slice(-4) : ''
  }

  const displayNotify = (type, content) => {
    setAlertState({
      open: true,
      message: content,
      severity: type,
    })
  }

  return (
    <ToastProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="Layout">
        <Disclosure
          as="nav"
          className={`header fixed z-50 w-full font-poppins-semibold px-0 ${headerClass} ${visibleClass ? "block" : "hidden"} `}
          style={headerStyle}
        >
          {({ open }) => (
            <>
              <div ref={ref} className="border-b-4 border-blue-100">
                <div className="m-auto container">
                  <div className="relative flex items-center justify-between">
                    <div className="flex items-center justify-between flex-1 min-w-full sm:items-stretch xl:px-0 py-1.5 lg:py-1 relative h-11">
                      <div className="flex items-center flex-shrink-0 absolute left-1/2 transform -translate-x-1/2 top-2">
                        <Link to="/">
                          <img
                            className="block w-auto h-16 sm:h-24"
                            src="/logo.png"
                            alt="logo image"
                          /> 
                        </Link>
                      </div>
                      <div className="sm:flex justify-start items-center hidden">
                        {socialLinks.map((item, index) => (
                          <a href={item.link} target="_blank" rel="noreferrer" className="mr-3" key={index}>
                            <img src={item.icon} alt="social icon" className="w-8" />
                          </a>
                        ))}
                      </div>
                      <div className="pr-16 hidden sm:block text-white">
                        {connected ? (
                          <div className="py-1 px-4 rounded bg-gradient-to-r from-blue-200 to-blue-300 border-2 border-blue-100">
                            {sliceAddress(walletAddress)}
                          </div>
                        ) : (
                          <div className="py-1 px-4 rounded cursor-pointer bg-gradient-to-r from-blue-200 to-blue-300 border-2 border-blue-100" onClick={connectHandler}>CONNECT</div>
                        )}
                      </div>
                      <div className="hidden lg:ml-6 py-1">
                        <div className="flex space-x-4 align-middle h-full">
                          <ul className="navMenus">
                            {Menus.map(dt => (
                              <li className={`mx-1 ${dt.active === page ? "active" : ""}`} key={dt.name} >
                                {dt.type == 'internal' ?
                                  <Link
                                    activeClassName="active"
                                    to={dt.path}
                                    partiallyActive={!!dt.partiallyActive}
                                    target={dt.target}
                                    className="px-2"
                                  >
                                    {dt.name}
                                  </Link>
                                  :
                                  <a href={dt.path} target={dt.target} className="px-2">
                                    {dt.name}
                                  </a>
                                }
                              </li>
                            ))}
                            <li className="px-3 text-white">
                              {connected ? (
                                <div className="py-1 px-4 rounded bg-gradient-to-r from-blue-200 to-blue-300 border-2 border-blue-100">
                                  {sliceAddress(walletAddress)}
                                </div>
                              ) : (
                                <div className="py-1 px-4 rounded cursor-pointer bg-gradient-to-r from-blue-200 to-blue-300 border-2 border-blue-100" onClick={connectHandler}>CONNECT</div>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center ">
                      {/* Mobile menu button*/}
                      <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md menuToggleIcon focus:outline-none">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block w-6 h-6 text-blue-50" aria-hidden="true" />
                        ) : (
                          <MenuIcon
                            className="block w-6 h-6 text-blue-50"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>
              </div>
              <Disclosure.Panel className="bg-black lg:block">
                <div className="px-2 pt-8 sm:pt-14 pb-6 space-y-1">
                  <div>
                    <ul className="flex-col navMenus">
                      {Menus.map(dt => (
                        <li className={`mx-1 py-1 my-1 ${dt.active === page ? "active" : ""}`} key={dt.name}>
                          <Link
                            activeClassName="active"
                            to={dt.path}
                            target={dt.target}
                            className="px-2"
                          >
                            {dt.name}
                          </Link>
                        </li>
                      ))}
                      <li className="px-3 text-white mt-2 sm:hidden">
                        {connected ? (
                          <div className="py-1 px-4 rounded bg-gradient-to-r from-blue-200 to-blue-300 border-2 border-blue-100">
                            {sliceAddress(walletAddress)}
                          </div>
                        ) : (
                          <div className="py-1 px-4 rounded cursor-pointer bg-gradient-to-r from-blue-200 to-blue-300 border-2 border-blue-100" onClick={connectHandler}>CONNECT</div>
                        )
                        }
                      </li>
                    </ul>
                    <div className="flex justify-center items-center mt-6 mb-2 sm:hidden">
                      {socialLinks.map((item, index) => (
                          <a href={item.link} target="_blank" rel="noreferrer" className="mr-3" key={index}>
                            <img src={item.icon} alt="social icon" className="w-8" />
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div
          className="relative flex flex-col contentWrapper bg-white"
        >
          <main
            className={`content container mx-auto flex-grow flex flex-col ${contentClassName} ${visibleClass ? '' : 'pb-0'} `}
          >
            {children}
          </main>
        </div>
        <Footer />
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={alertState.open}
          autoHideDuration={10000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
            className="alert-md"
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </div>
    </ToastProvider>
  )
}

const stateProps = (state) => ({
  web3: state.web3,
  onBoard: state.onBoard,
  walletAddress: state.walletAddress,
  connected: state.connected
});

const dispatchProps = (dispatch) => ({
  setChainId: (id) => dispatch(setChainId(id)),
  setWeb3: (data) => dispatch(setWeb3(data)),
  setEthWeb3: (data) => dispatch(setEthWeb3(data)),
  setPolWeb3: (data) => dispatch(setPolWeb3(data)),
  setOnBoard: (data) => dispatch(setOnBoard(data)),
  setWalletAddress: (address) => dispatch(setWalletAddress(address)),
  setConnected: (status) => dispatch(setConnected(status))
})

export default connect(stateProps, dispatchProps)(Layout);
