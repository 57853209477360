export const Menus = [
  {
    name: "HOME",
    path: "/",
    target: "",
    type: 'external',
    active: "home"
  },
  {
    name: "MY NFTS",
    path: "/mynfts",
    target: "",
    type: 'external',
    active: "mynfts"
  },
  {
    name: "SLIPSTREAM DEMO",
    path: "/demo",
    target: "",
    type: 'external',
    active: "demo"
  },
  {
    name: "DWARVES",
    path: "/dwarves",
    target: "",
    type: 'external',
    active: "dwarves"
  },
  {
    name: "COLLECTIONS",
    path: "/collection",
    target: "",
    type: 'external',
    active: "collection"
  }
]

